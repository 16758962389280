// 此功能暫時不使用

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'header',
    'darkenMask',
    'bgImg'
  ]

  static values = {
    blueIndex: { type: Number, default: -1 },
    whiteIndex: { type: Number, default: -1 },
    normalIndex: { type: Number, default: -1 }
  }

  connect () {
    this.currentIndex = 0
    this.mappingClasses = {}
    this._buildMapping()
  }

  changeMode ({ detail: { activeIndex, previousIndex } }) {
    ['blue', 'white', 'normal'].forEach(key => {
      const targetIndex = this[`${key}IndexValue`]
      const targetClass = this.mappingClasses[targetIndex]

      if (this._isLeave(targetIndex, activeIndex, previousIndex)) {
        this.headerTarget.classList.remove(targetClass)
        this._darkenMaskWhenWhiteMode(key, { enabled: false })
      } else if (this._isArrived(targetIndex, activeIndex, previousIndex)) {
        this.headerTarget.classList.add(targetClass)
        this._darkenMaskWhenWhiteMode(key, { enabled: true })
      }
    })

    this.currentIndex = activeIndex
  }

  _buildMapping () {
    ['blue', 'white', 'normal'].forEach(key => {
      if (this[`${key}IndexValue`] > -1) {
        this.mappingClasses[this[`${key}IndexValue`]] = `${key}-mode`
      }
    })
  }

  _isLeave (targetIndex, activeIndex, previousIndex) {
    return (targetIndex === previousIndex)
  }

  _isArrived (targetIndex, activeIndex, previousIndex) {
    return (targetIndex === activeIndex)
  }

  _darkenMaskWhenWhiteMode (mode, { enabled }) {
    if (mode === 'white') {
      this.darkenMaskTarget.classList.toggle('invisible', !enabled)
      this.darkenMaskTarget.classList.toggle('visible', enabled)
      this.darkenMaskTarget.previousElementSibling.previousElementSibling.classList.toggle('visible', !enabled)
      this.darkenMaskTarget.previousElementSibling.previousElementSibling.classList.toggle('invisible', enabled)
      this.darkenMaskTarget.previousElementSibling.classList.toggle('visible', !enabled)
      this.darkenMaskTarget.previousElementSibling.classList.toggle('invisible', enabled)
      this.darkenMaskTarget.nextElementSibling.classList.toggle('invisible', !enabled)
      this.darkenMaskTarget.nextElementSibling.classList.toggle('visible', enabled)
    }
  }
}
