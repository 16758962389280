import { Application } from '@hotwired/stimulus'

// Import and register all your controllers within this directory and all subdirectories
// Controller files must be named *_controller.js or *_controller.ts
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'

const application = Application.start()

// Configure Stimulus development experience
application.warnings = true
application.debug = false
if (process.env.NODE_ENV === 'development') {
  application.debug = true
  window.Stimulus = application
}
const context = require.context('controllers', true, /_controller\.(js|ts)$/)
application.load(definitionsFromContext(context))
