import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = {
    apiUrl: String
  }
  static targets = ['clinicName', 'currentNum', 'nextNum']
  connect(){
    this.fetchClinic()
  }

  async fetchClinic(){
    const response = await get(this.apiUrlValue, {responseKind: 'json'})
    if(response.ok){
      const resp = await response.json
      const respData = resp.data
      this.clinicNameTarget.innerText = respData.clinic_name
      this.currentNumTarget.innerText = respData.current_num
      this.nextNumTarget.innerText = respData.next_num
    }
  }
}
