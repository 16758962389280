import { Controller } from '@hotwired/stimulus'
import * as copy from 'copy-to-clipboard';

export default class extends Controller {
  toClipboard(e) {
    copy(e.currentTarget.dataset.copied);
    let tooltip = document.getElementById(e.currentTarget.dataset.tiptext)
    tooltip.innerHTML = "已複製!"

    setTimeout(function(){
      tooltip.innerHTML = "複製到剪貼簿"
    }, 3000)
  }
}
