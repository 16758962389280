import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    if (!this._isChrome()) {
      return
    }

    this.jump()
  }

  jump () {
    if (!location.hash.match(/^#/)) {
      return
    }

    const target = document.querySelector(decodeURI(location.hash))
    if (!target) {
      return
    }

    this.element.scrollTo(0, target.offsetTop)
  }

  _isChrome () {
    return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
  }
}
