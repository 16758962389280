import { Controller } from '@hotwired/stimulus'

import Siema from 'siema'

export default class extends Controller {
  static targets = ['panel', 'prev', 'next']

  connect () {
    this.instance = new Siema({
      selector: this.panelTarget,
      perPage: {
        0: 1.15,
        768: 1.9,
        1024: 2.5,
        1440: 3
      }
    })
    this._renderButtons()
  }

  prev () {
    this.instance.prev()
    this._renderButtons()
  }

  next () {
    this.instance.next()
    this._renderButtons()
  }

  _renderButtons () {
    const prevToggleClass = this.prevTarget.dataset.toggleClass
    const isPrevShow = this.instance.currentSlide !== 0
    this.prevTarget.classList.toggle(prevToggleClass, isPrevShow)

    const nextToggleClass = this.nextTarget.dataset.toggleClass
    const isNextShow = this.instance.currentSlide + this.instance.perPage < this.instance.innerElements.length
    this.nextTarget.classList.toggle(nextToggleClass, isNextShow)
  }
}
