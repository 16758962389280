import { Controller } from '@hotwired/stimulus'

const MODES = [
  'blue',
  'white',
  'normal'
]

export default class extends Controller {
  static targets = [
    'scrollContainer',
    'whitePoint',
    'normalPoint',
    'header',
    'darkenMask',
  ]

  connect () {
    this.scrollTimer = null

    this.scrollContainerTarget.addEventListener('scroll', (e) => {
      // 暫時註解掉debounce效果，因為目前不使用首頁不使用snap-mandatory
      // 偵測scroll停下來，才執行偵測，若不是停下來的狀態，此時設定class會有神奇的抖動
      // if (this.scrollTimer) { clearTimeout(this.scrollTimer) }
      // this.scrollTimer = setTimeout(() => {
        this.detectMode(e)
      // }, 100)
    })
    window.addEventListener('resize', (e) => {
      this.detectMode(e)
    })

    this.detectMode()
  }

  detectMode () {
    const mode = MODES[this._findModeIdx()]
    if (mode !== this.currentMode) {
      MODES.forEach(mode => {
        this.headerTarget.classList.remove(`${mode}-mode`)
      })
      this.headerTarget.classList.add(`${mode}-mode`)
      this.currentMode = mode
    }

    this._darkenMaskWhenWhiteMode(mode)
  }

  // 不可為cache variable，當RWD時，offetTop可能會變
  _pointsTopMapping () {
    return [this.whitePointTarget, this.normalPointTarget].map(point => point.offsetTop)
  }

  _findModeIdx () {
    const currentScrollTop = this.scrollContainerTarget.scrollTop
    const idx = this._pointsTopMapping().findIndex(pointTop => currentScrollTop < pointTop)

    return (idx < 0 ? this._pointsTopMapping().length : idx)
  }

  _darkenMaskWhenWhiteMode (mode) {
    const enabled = mode === 'white'

    this.darkenMaskTarget.classList.toggle('invisible', !enabled)
    this.darkenMaskTarget.classList.toggle('visible', enabled)
    this.darkenMaskTarget.previousElementSibling.previousElementSibling.classList.toggle('visible', !enabled)
    this.darkenMaskTarget.previousElementSibling.previousElementSibling.classList.toggle('invisible', enabled)
    this.darkenMaskTarget.previousElementSibling.classList.toggle('visible', !enabled)
    this.darkenMaskTarget.previousElementSibling.classList.toggle('invisible', enabled)
    this.darkenMaskTarget.nextElementSibling.classList.toggle('invisible', !enabled)
    this.darkenMaskTarget.nextElementSibling.classList.toggle('visible', enabled)
  }
}
