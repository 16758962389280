import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  noscroll (e) {
    const target = window[e.currentTarget.htmlFor]
    if (target) {
      const type = target.type.toLowerCase()
      if (type === 'checkbox') {
        target.checked = !target.checked
      } else if (type === 'radio') {
        target.checked = true
      }
    }
    e.preventDefault()
  }
}
