import { Controller } from '@hotwired/stimulus'

import Swiper from 'swiper/swiper-bundle'
import 'swiper/swiper-bundle.css'

export default class extends Controller {
  static targets = ['slide']

  static values = {
    index: { type: Number, default: 0 },
    vertical: { type: Boolean, default: false },
    pagination: { type: Boolean, default: false },
    bulletClass: { type: String, default: '' },
    bulletActiveClass: { type: String, default: '' }
  }

  connect () {
    this._addClassForTargets()

    this.instance = new Swiper(this.element, {
      direction: this.verticalValue ? 'vertical' : 'horizontal',
      freeMode: this.verticalValue,
      slidesPerView: 'auto',
      centeredSlides: true,
      keyboard: {
        enabled: true
      },
      mousewheel: this.verticalValue,
      pagination: this.paginationValue
        ? {
            el: '.swiper-pagination',
            clickable: true,
            bulletClass: `swiper-pagination-bullet ${this.bulletClassValue}`.trim(),
            bulletActiveClass: `swiper-pagination-bullet-active ${this.bulletActiveClassValue}`.trim()
          }
        : false
    })

    this.instance.slideTo(this.indexValue, 0, false)
  }

  _addClassForTargets () {
    this.slideTargets.forEach((target) => {
      target.classList.add('swiper-slide')
    })
  }
}
