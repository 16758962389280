// queryParent(selector)(dom)
const queryParent = (selector) => (dom) => {
  if (!dom) {
    return
  }

  let parent = dom.parentNode

  while (parent) {
    if (parent === document) {
      parent = null
      break
    } else if (parent.matches(selector)) {
      break
    }
    parent = parent.parentNode
  }

  return parent
}

export { queryParent }
