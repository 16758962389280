import { Controller } from '@hotwired/stimulus'

const BREAKPOINT = 768
const WIDTH_BASE = 1440.0

export default class extends Controller {
  connect () {
    if (this._isChrome()) {
      this._setClass()
      this.setScale()
    }
  }

  setScale () {
    if (!this._isChrome()) {
      return
    }

    // 抓window的可視範圍
    const width = window.innerWidth
    const height = window.innerHeight

    if (width < BREAKPOINT) {
      this.element.style.height = ''
      this.element.style.transform = ''
      return
    }

    // 需要scale的比率
    const ratio = width / WIDTH_BASE
    // 放大高度，再scale才會正常
    const finalHeight = height * WIDTH_BASE / width
    this.element.style.height = `${finalHeight}px`
    this.element.style.transform = `scale(${ratio})`
  }

  _isChrome () {
    return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
  }

  _setClass () {
    document.querySelector('html').classList.add('chrome-browser')
  }
}
