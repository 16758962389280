// 此功能暫時不使用

import { Controller } from '@hotwired/stimulus'

import { queryParent } from 'components/selector_tools'

// unit: micro seconds
const SLIDE_PERIOD = {
  wheel: 1250,
  touch: 1000,
  keyup: 750
}

// unit: seconds
const TRANSITION_TIME = 0.5

const EXCLUDE_SELECTOR = 'label'

export default class extends Controller {
  static targets = [
    'slide'
  ]

  static values = {
    enable: { type: Boolean, default: false }
  }

  connect () {
    this.lastIndex = 0
    this.currentIndex = 0
    this.lastTouchPos = null
    this.lastSlideTimeStamp = 0
    this.changeEnableProcessing = false

    this.enableSlideOne = this.enableValue
    this._changeEnable(this.enableSlideOne)
  }

  scrollSlide (e) {
    if (!this.enableSlideOne) {
      this._detectCurrentIndex()
    }
  }

  wheelSlide (e) {
    if (this.changeEnableProcessing) {
      e.preventDefault()
      e.stopPropagation()
      return false
    }

    if (!this.enableSlideOne) {
      this._detectCurrentIndex()
      return true
    }

    e.preventDefault()
    e.stopPropagation()

    const direction = this._wheelDirection(e.deltaY)
    this._scrollOne('wheel', direction)

    return false
  }

  touchSlideStart (e) {
    if (this.changeEnableProcessing) {
      e.preventDefault()
      e.stopPropagation()
      return false
    }

    // 避免按下label時，會無法使用label來改變checkbox的功能
    if (e.target.matches(EXCLUDE_SELECTOR) || queryParent(EXCLUDE_SELECTOR)(e.target)) {
      return true
    }

    if (!this.enableSlideOne) {
      return true
    }

    this.lastTouchPos = e.changedTouches[0].pageY
  }

  touchSlideEnd (e) {
    if (this.changeEnableProcessing) {
      e.preventDefault()
      e.stopPropagation()
      return false
    }

    // 避免按下label時，會無法使用label來改變checkbox的功能
    if (e.target.matches(EXCLUDE_SELECTOR) || queryParent(EXCLUDE_SELECTOR)(e.target)) {
      return true
    }

    if (!this.enableSlideOne) {
      this._detectCurrentIndex()
      return true
    }

    if (!this.lastTouchPos) {
      return false
    }

    const currentTouchPos = e.changedTouches[0].pageY
    const direction = this._touchDirection(currentTouchPos, this.lastTouchPos)
    this._scrollOne('touch', direction)

    this.lastTouchPos = null

    return false
  }

  keyupSlide (e) {
    if (this.changeEnableProcessing) {
      e.preventDefault()
      e.stopPropagation()
      return false
    }

    if (!this.enableSlideOne) {
      this._detectCurrentIndex()
      return true
    }

    const direction = this._keyupDirection(e)
    this._scrollOne('keyup', direction)

    return false
  }

  moveCurrentPos () {
    if (!this.enableSlideOne) {
      return
    }

    const targetDom = this.slideTargets[this.currentIndex]
    this._moveTargetPos(targetDom, { animation: false })
  }

  _changeEnable (enable) {
    this.changeEnableProcessing = true
    this.enableSlideOne = enable
    const offsetTop = this.slideTargets[this.currentIndex].offsetTop - this.element.offsetTop

    if (enable) {
      this.element.style.overflowY = ''
      this.element.scrollTo(0, 0)
      this._moveTargetPos(this.slideTargets[this.lastIndex], { animation: false })
      setTimeout(() => {
        this._moveTargetPos(this.slideTargets[this.currentIndex])
      }, 0)
    } else {
      const disableStyles = () => {
        this.element.style.overflowY = 'auto'
        this.element.scrollTo(0, offsetTop)
        this.element.style.marginTop = '0'
        this.element.style.transition = ''
      }
      // 若已經開啟，需要等animation結束
      if (this.element.style.transition) {
        const transitionendEvent = () => {
          disableStyles()
          this.element.removeEventListener('transitionend', transitionendEvent)
        }
        this.element.addEventListener('transitionend', transitionendEvent)
      } else {
        disableStyles()
      }
    }
    window.setTimeout(() => {
      this.changeEnableProcessing = false
    }, TRANSITION_TIME * 1000)
  }

  _scrollOne (type, direction) {
    const now = Date.now()
    if (now - this.lastSlideTimeStamp <= SLIDE_PERIOD[type]) {
      return
    }

    const nextIndex = this._getNextIndex(direction)
    if (nextIndex === undefined || this.currentIndex === nextIndex) {
      return
    }

    const targetDom = this.slideTargets[nextIndex]
    this._moveTargetPos(targetDom)

    this.dispatch('activeIndexChange', {
      detail: {
        activeIndex: nextIndex, previousIndex: this.currentIndex
      }
    })

    this.lastIndex = this.currentIndex
    this.currentIndex = nextIndex
    this.lastSlideTimeStamp = now

    // Try Lock
    // if (nextIndex === 2) {
    //   this._changeEnable(false)
    // }
  }

  _wheelDirection (delta) {
    if (delta > 0) {
      return 'DOWN'
    } else if (delta < 0) {
      return 'UP'
    } else {
      return 'NO'
    }
  }

  _touchDirection (currentTouchPos, lastTouchPos) {
    if (Math.abs(currentTouchPos - lastTouchPos) <= 30) {
      return 'NO'
    } else if (currentTouchPos > lastTouchPos) {
      return 'UP'
    } else if (currentTouchPos < lastTouchPos) {
      return 'DOWN'
    } else {
      return 'NO'
    }
  }

  _keyupDirection (e) {
    if (e.key === 'ArrowUp' || e.key === 'PageUp') {
      return 'UP'
    } else if (e.key === 'ArrowDown' || e.key === 'PageDown') {
      return 'DOWN'
    } else {
      return 'NO'
    }
  }

  _getNextIndex (direction) {
    if (direction === 'UP') {
      return (this.currentIndex === 0 ? 0 : this.currentIndex - 1)
    } else if (direction === 'DOWN') {
      return (this.currentIndex === this.slideTargets.length - 1 ? this.currentIndex : this.currentIndex + 1)
    }
  }

  _moveTargetPos (targetDom, { animation = true } = {}) {
    const targetParentDom = targetDom.parentNode
    const offsetTop = targetDom.offsetTop - targetParentDom.offsetTop

    this.element.style.marginTop = `${offsetTop * -1}px`
    this.element.style.transition = animation ? `margin-top ${TRANSITION_TIME}s linear` : ''
  }

  _detectCurrentIndex () {
    const currentScrollTop = this.element.scrollTop
    let nextIndex = null

    for (let i = 0; i < this.slideTargets.length; i++) {
      const thisSlide = this.slideTargets[i]
      // const thisSlideOffsetTop = thisSlide.offsetTop - this.element.offsetTop
      const nextSlide = this.slideTargets[i + 1]
      // const nextSlideOffsetTop = nextSlide ? (nextSlide.offsetTop - this.element.offsetTop) : 0
      if (currentScrollTop >= thisSlide.offsetTop) {
        nextIndex = i
        if (nextSlide && currentScrollTop < nextSlide.offsetTop) {
          break
        }
      }
    }

    if (this.currentIndex !== nextIndex) {
      this.dispatch('activeIndexChange', {
        detail: {
          activeIndex: nextIndex, previousIndex: this.currentIndex
        }
      })

      this.lastIndex = this.currentIndex
      this.currentIndex = nextIndex

      // Try Lock
      // if (nextIndex === 1) {
      //   this._changeEnable(true)
      // }
    }
  }
}
