import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['questionsNav'];

  connect () {
    const scrollTarget = document.querySelector('[data-controller~="anchor-jump"]')
    const nav = this.questionsNavTarget
    // 頁面載入時的offsetTop高度
    const navOffsetTop = nav.offsetTop
    this.scroll = (e) => {
      // 如果offsetTop高度改變則需要反白
      const isWhite = navOffsetTop != nav.offsetTop
      this.questionsNavTarget.classList.toggle('bg-white01', isWhite)
    }
    scrollTarget.addEventListener('scroll', this.scroll, true)
  }

  disconnect () {
    const scrollTarget = document.querySelector('[data-controller~="anchor-jump"]')
    scrollTarget.removeEventListener('scroll', this.scroll, true)
  }
}
