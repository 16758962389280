import { Controller } from '@hotwired/stimulus'

function highlightText (text, searchTerm) {
  return text.replace(
    searchTerm,
    `<span class='text-orange01'>${searchTerm}</span>`
  )
}

// questions的json結構：
// {
//   "診療": [
//     { "accordionHeader": "Q1 xxxx", "accordionContent": ["xxx1", "xxx2", "xxx3"] },
//     { "accordionHeader": "Q2 xxxx", "accordionContent": ["xxx1", "xxx2", "xxx3"] }
//   ],
//   "就診": [
//     { "accordionHeader": "Q1 xxxx", "accordionContent": ["xxx1", "xxx2", "xxx3"] },
//     { "accordionHeader": "Q2 xxxx", "accordionContent": ["xxx1", "xxx2", "xxx3"] }
//   ]
// }

export default class extends Controller {
  static targets = ['input', 'suggestions', 'blankSlate']
  static values = {
    questions: { type: Object, default: {} }
  }

  connect() {
    this.renderAll()
  }

  toggleBlankSlate (cond) {
    this.blankSlateTarget.classList.toggle('hidden', cond)
  }

  renderAll () {
    const sections = Object.entries(this.questionsValue).flatMap(
      ([sectionName, questions], i) => {
        const accordions = questions.map(
          ({ accordionHeader, accordionContent }, j) => {
            return `
              <details class='accordion' id="${i},${j}">
                <summary class='accordion_header'>${accordionHeader}</summary>
                <div class='accordion_content'>
                  ${accordionContent
                    .map((p) => `<p class='mb-2'>${p}</p>`)
                    .join('')}
                </div>
              </details>
            `
          }
        )
        return `
          <h1 class='relative pt-10 lg:pt-20 text-18px font-500 pb-4 md:text-28px md:pb-6'>
            <a id='${sectionName}' class="absolute -top-26"></a>
            ${sectionName}
          </h1>
          <div class='flex flex-col gap-y-4 md:gap-y-8'>${accordions.join('')}</div>
        `
      }
    )
    this.suggestionsTarget.innerHTML = sections.join('')
    this.toggleBlankSlate(true)
  }

  filterInput (event) {
    event.preventDefault()
    const searchTerm = this.inputTarget.value.trim()
    if (!searchTerm) {
      this.renderAll()
    } else {
      this.renderFilteredResult(searchTerm)
    }
  }

  renderFilteredResult (term) {
    const filteredResult = Object.entries(this.questionsValue).flatMap(
      ([sectionName, questions], i) => {
        return questions
          .map((question, j) => ({ ...question, j }))
          .filter(({ accordionHeader }) => {
            return accordionHeader.includes(term)
          })
          .map(
            ({ accordionHeader, j }) => `
              <p
                data-action="click->default--pages--questions--search-bar#renderSelectedSection"
                data-section="#${sectionName}"
                data-article="${i},${j}"
                class='cursor-pointer border-b border-solid border-line last:border-0 p-4 bg-gray04 text-gray01 text-14px md:text-16px'
              >
                ${sectionName}
                <span class="mx-2">&#124;</span>
                ${highlightText(accordionHeader, term)}
              </p>
            `
          )
      }
    )
    if (filteredResult.length > 0) {
      this.suggestionsTarget.innerHTML = `
        <div class='search-results bg-gray04'>
          <p class='border-b border-solid border-line last:border-0 p-4 text-purple01 text-14px md:text-16px'>
            共找到<span class='mx-1 text-orange01'>${filteredResult.length}</span>筆結果
          </p>
          ${filteredResult.join('')}
        </div>
      `
    } else {
      this.suggestionsTarget.innerHTML = ''
    }
    this.toggleBlankSlate(filteredResult.length)
  }

  renderSelectedSection (event) {
    const { section, article } = event.currentTarget.dataset
    this.renderAll()
    location.hash = section
    const selectedAccordion = document.getElementById(article)
    selectedAccordion.open = true
  }
}
